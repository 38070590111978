<template>
  <div class="useral-answer-ticket">
    <div class="view-side mt-3 sm:mt-0">
      <div class="view-box">
        <vs-row>
          <vs-col>
            <div>
              <custom-validate-textarea :label="$t('tickets.support.labels.message')"
                                        :placeholder="$t('tickets.support.placeholder.message')"
                                     :classes="{'w-full': true}"
                                     align="right"
                                     rtl
                                     v-model="ticket.message"/>

              <div class="attachment-inputs-section">
                <div class="attach-inputs">
                  <template v-for="(attach, key) in ticket.attaches">
                    <custom-file-input :label="$t('tickets.support.labels.dynamicAttach', {number: key + 1})"
                                       :is-empty="true"
                                       :key="key"
                                       logo-type="formData"
                                       accept-type="*/*"
                                       :styles="{width: '100%'}"
                                       :preview="false"
                                       :upload-text="$t('tickets.support.notifications.attachUpload')"
                                       v-model="attach.file"
                                       :disabled="false"/>
                  </template>
                </div>
                <button class="insert-new-attach-btn" @click="handleInsertAttachInput">
                  {{ $t('tickets.support.labels.insertAttach') }}
                </button>
              </div>
            </div>
          </vs-col>
        </vs-row>
      </div>
    </div>

    <vs-button v-show="false"
               id="answerTicketBTN"
               @click="sendData"/>
  </div>
</template>

<script>
  import axios from 'axios'
  import CustomValidateInput from '../../../../../../components/customInput/customValidateInput'
  import CustomValidateTextarea from '../../../../../../components/customInput/customValidateTextarea'
  import {checkUserPermissions} from '../.././../../../../assets/js/functions'
  import CustomFileInput from "../../../../../../components/customInput/customeFileInput";
  import CustomIcon from "../../../../../../components/customIcon/customIcon";
  import {sendMessageSupportTicket} from "../../../../../../http/requests/tickets";

  export default {
    name: 'answerSupportTicket',
    components: {
      CustomIcon,
      CustomFileInput,
      CustomValidateTextarea,
      CustomValidateInput
    },
    props: {
      courtId: 0
    },
    data() {
      return {
        suggestServicePromptStatus: false,
        ticket: {
          message: {
            value: '',
            isValid: false
          },
          attaches: [{
            file: {
              value: '',
              isValid: true
            }
          }]
        }
      }
    },
    created() {
    },
    methods: {
      checkUserPermissions(permission) {
        return checkUserPermissions(permission)
      },
      handleClick(id) {
        document.getElementById(id).click()
      },
      handleInsertAttachInput () {
        this.ticket.attaches.push({
          file: {
            value: '',
            isValid: true
          }
        })
      },
      sendData() {
        if (!this.ticket.message.isValid) {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('tickets.support.validators.message'),
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400,
            color: 'danger'
          })
          return false
        }

        let attaches = []
        let formData = new FormData()
        let attachCount = 0
        this.ticket.attaches.forEach((attach) => {
          if (attach.file.value) {
            attaches.push(formData)
            formData.append(`attachments[${attachCount}]`, attach.file.value);
            attachCount++
          }
        })

        formData.append('message', this.ticket.message.value);

        sendMessageSupportTicket(this.$route.params.id, formData).then(() => {
          this.$vs.notify({
            title: this.$t('alert.message.title'),
            text: this.$t('tickets.support.notifications.sendMessage.success'),
            icon: 'icon-check',
            iconPack: 'feather',
            time: 2400,
            color: 'success'
          })
          this.$emit('insert')
        }).catch((error) => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          } else {
            const error_mapper = {
              'message': this.$t('tickets.support.notifications.parseError.message'),
              'attachments': this.$t('tickets.support.notifications.parseError.attachments'),
            }

            switch (error.response.status) {
              case 422:
                Object.keys(error.response.data.errors).forEach((error_key) => {
                  const err = error_key.toString().split('.')
                  if (error_mapper[err[err.length - 1]]) {
                    this.$vs.notify({
                      title: this.$t('alert.error.title'),
                      text: error_mapper[err[err.length - 1]],
                      color: 'danger',
                      icon: 'icon-alert-circle',
                      iconPack: 'feather',
                      time: 2400
                    })
                    error_mapper[err[err.length - 1]] = null
                  } else if (error_mapper[err[0]]) {
                    this.$vs.notify({
                      title: this.$t('alert.error.title'),
                      text: error_mapper[err[0]],
                      color: 'danger',
                      icon: 'icon-alert-circle',
                      iconPack: 'feather',
                      time: 2400
                    })
                    error_mapper[err[0]] = null
                  }
                })
                break

              default:
                this.$vs.notify({
                  title: this.$t('alert.error.title'),
                  text: this.$t('tickets.support.notifications.sendMessage.error'),
                  color: 'danger',
                  icon: 'icon-alert-circle',
                  iconPack: 'feather',
                  time: 2400
                })
                break
            }
          }
        })
      }
    }
  }
</script>

<style lang="scss">
  .useral-answer-ticket {
    width: 100%;
    height: 100%;
    display: flex;

    @media (max-width: 767px) {
      display: block;
    }

    .menu-side {
      width: 200px;
      min-width: 200px;
      height: 100%;
      padding: 0 10px 0 0;

      @media (max-width: 767px) {
        width: 100%;
        min-width: 100%;
        padding: 0;
        height: fit-content;
      }

      ul {

        li {
          position: relative;
          border: 1px solid #cecece;
          border-radius: .5rem;
          margin-bottom: 10px;
          padding: 0 10px;
          line-height: 40px;
          cursor: pointer;
          transition: all 0.3s ease;

          .icon-chevron-left {
            position: absolute;
            height: fit-content;
            top: 0;
            bottom: 0;
            right: 6px;
            margin: auto;
          }

          &:hover {
            transform: translateX(5px);
          }
        }
      }
    }

    .view-side {
      flex-grow: 1;
      height: 100%;
      /*border: 1px solid #cecece;*/
      padding: 0;
      border-radius: .5rem;
      /*overflow-y: auto;*/

      .view-box {
        /*min-height: 300px;*/
        width: 100%;
        height: 100%;
        padding: 0;
        /*overflow: auto;*/

        .select-type {
          .select-type-label {
            font-size: 17px;
            margin-bottom: 10px;
          }

          .ticket-type {
            display: flex;
            align-items: center;
            border: 1px solid #cdcdcd;
            border-radius: 0.5rem;
            padding: 15px 10px;
            margin-bottom: 10px;
            transition: all ease 0.5s;
            height: calc(100% - 50px);
            overflow-y: auto;

            .ticket-content {
              width: calc(100% - 40px);

              .ticket-title {
                font-size: 16px;
                margin-bottom: 10px;
              }

              .ticket-description {
                font-size: 14px;
              }
            }

            .ticket-arrow {
              width: 40px;
            }

            &:hover {
              cursor: pointer;
              background-color: #f5f5f5;
              transform: translateX(5px);
            }
          }
        }

        .select-service {
          position: relative;

          .suggest-modal-button, .new-suggest-modal-button {
            position: absolute;
            bottom: 0;
            right: 6px;
            top: 4px;
            width: 15px;
            height: 15px;
            padding: 5px;
            border: 1px solid #00bcd4;
            border-radius: .5rem;
            color: #00bcd4;
            background: white;
            cursor: pointer;
          }
        }

        .custom-validate-input {
          margin-top: 5px;
          margin-bottom: 7px;
        }

        .attachment-inputs-section {
          display: flex;
          align-items: flex-start;

          .attach-inputs {
            flex-grow: 1;
            margin-right: 10px;
          }

          .insert-new-attach-btn {
            margin-top: 15px;
            padding: 5px 10px;
            border-radius: 0.5rem;
            border: 1px solid #cdcdcd;
            background-color: inherit;
            min-height: 36px;

            &:hover {
              background-color: #f0f0f0;
              cursor: pointer;
            }
          }
        }

        .logo-input {
          min-height: 100px;
        }

        .custom-profile-image-input {
          label {
            height: 110px;
            width: 110px;
          }
        }

        .date-picker-item {
          border: 0 !important;
          border-radius: 0.4rem;
          position: relative;

          .date-picker-label {
            font-size: 12px;
            position: absolute;
            top: -10px;
            left: 5px;
            padding: 0 5px;
            background-color: #ffffff;
            z-index: 1;
          }

          .vpd-input-group {
            width: 20px !important;
            position: absolute;
            top: 6px;
            left: 2px;

            .vpd-icon-btn {
              opacity: 1 !important;
              height: 25px !important;
              width: 20px !important;
              background-color: inherit !important;

              svg {
                fill: #000000;
                overflow: visible !important;
              }
            }

            .form-control {
              border: 0;
              width: 100%;
              display: none;
              line-height: 35px;
            }
          }
        }

        .custom-input:last-child {
          margin-bottom: 0;
        }

        .input-field-item {
          position: relative;
          border: 1px solid #cdcdcd;
          border-radius: 0.4rem;
          padding: 5px 10px;
          margin: 15px 0;
          display: flex;
          justify-content: space-around;
          min-height: 35px;

          &.invalid {
            border-color: #b00000;

            .input-field-label {
              color: #b00000;
            }
          }

          .input-field-label {
            position: absolute;
            font-size: 12px;
            top: -10px;
            left: 8px;
            background-color: #ffffff;
            padding: 0 5px;
          }

          .radio-item {
            display: flex;
            align-items: center;
            direction: rtl;

            label {
              margin-left: 5px;
            }
          }
        }

        div.edit-user-field {
          position: relative;

          span.label {
            font-size: 12px;
            position: absolute;
            top: -10px;
            left: 7px;
            z-index: 10000;
            background: #ffffff;
            padding: 0 5px;
          }

          .useral-custom-element-select {
            margin: 15px 0;

            .selected {
              height: 35px;
              line-height: 35px;
              font-size: 13px;
            }

            .items {
              line-height: 35px;

              div {
                height: 35px;
                font-size: 13px;
              }
            }
          }
        }

        .error-alert {
          display: inline-block;
          border: 1px solid #ffba00;
          border-radius: .5rem;
          color: #ffba00;
          padding: 0 10px;
          line-height: 50px;
          height: 50px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 15px;

          i {
            font-size: 15px;
          }
        }

        &::-webkit-scrollbar {
          display: block;
          border-radius: .2rem;
          width: 10px;
          background: #f9f9fd;
          right: 10px;
        }

        &::-webkit-scrollbar-thumb {
          display: block;
          border-radius: .2rem;
          background: #cecece;
        }
      }
    }
  }
</style>
